
import { Getter } from "vuex-class";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IndexPublicationPayload, Publication, PublicationType } from "@/store/modules/publications.store";

@Component
export default class PageSellerPartPublications extends Vue {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("publications/all") publications!: Publication[];
}
